<template>
  <div>
    <q-form ref="editForm">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-card title="기본정보" class="cardClassDetailForm">
            <template slot="card-button">
              <q-btn-group outline >
                <!-- <c-btn
                  v-show="Boolean(nearAccident.iimNearAccidentId) && !disabled && editable"
                  :url="completeUrl"
                  :isSubmit="isComplete"
                  :param="nearAccident"
                  mappingType="PUT"
                  label="완료"
                  icon="check"
                  @beforeAction="completeNearAccident"
                  @btnCallback="completeCallback" 
                /> -->
                <!-- [S]결재관련 버튼 -->
                <!-- sysApprovalRequestId: 상세조회시 관련 결재요청일련번호 확인 -->
                <!-- popupParam.isApprContent: 결재요청/결재처리 팝업의 component에 해당 페이지 호출시 true -->
                <!-- approvalStatusCd: 결재요청건에 대한 상태코드 -->
                <!-- popupParam.approvalDocType: TODO(결재할문서에서 상세페이지호출시) -->
                <c-appr-btn 
                  v-if="!isApprDisabled"
                  ref="appr-btn"
                  name="danger-journal-appr-btn"
                  :editable="editable"
                  :approvalInfo="approvalInfo"
                  @beforeApprAction="saveDataAppr"
                  @callbackApprAction="approvalCallback"
                  @requestAfterAction="getDetail"
                />
                <c-btn
                  v-if="Boolean(nearAccident.iimNearAccidentId) && !isApprDisabled && !disabled && editable" 
                  label="삭제" icon="delete_forever" @btnClicked="remove" />
                <c-btn
                  v-show="!disabled && editable"
                  :url="saveUrl"
                  :isSubmit="isSave"
                  :param="nearAccident"
                  :mappingType="saveType"
                  label="저장"
                  icon="save"
                  @beforeAction="saveNearAccident"
                  @btnCallback="saveCallback" 
                />
              </q-btn-group>
            </template>
            <template slot="card-detail">
              <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <c-text 
                  required
                  :editable="editable" 
                  name="iimNearAccidentName" 
                  label="아차사고명"
                  v-model="nearAccident.iimNearAccidentName"
                />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-text 
                  :editable="editable" 
                  :disabled="true"
                  name="iimNearAccidentNo" 
                  label="아차사고번호"
                  v-model="nearAccident.iimNearAccidentNo"
                />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-multi-select
                  required
                  codeGroupCd="IIM_NEAR_KIND_CD"
                  :editable="editable"
                  :disabled="disabled"
                  :isArray="false"
                  itemText="codeName"
                  itemValue="code"
                  name="iimNearKindCds"
                  label="아차사고종류"
                  v-model="nearAccident.iimNearKindCds"
                >
                </c-multi-select>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-datepicker
                  required
                  :editable="editable"
                  type="datetime"
                  :disabled="disabled"
                  :minuteStep="10"
                  label="발생일시"
                  name="occurrenceDt"
                  v-model="nearAccident.occurrenceDt"
                />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-process
                  required
                  :editable="editable"
                  :disabled="disabled"
                  label="공정"
                  name="processCd"
                  v-model="nearAccident.processCd"
                >
                </c-process>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-text 
                  :editable="editable"
                  :disabled="disabled"
                  name="occurenceLocation" 
                  label="발생가능장소"
                  v-model="nearAccident.occurrenceLocation"
                />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-select
                  :editable="editable"
                  :disabled="disabled"
                  type="edit"
                  codeGroupCd="IIM_GRADE_CD"
                  itemText="codeName"
                  itemValue="code"
                  name="iimGradeCd"
                  label="아차사고등급"
                  v-model="nearAccident.iimGradeCd"
                >
                </c-select>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-field
                  :editable="editable"
                  :disabled="disabled"
                  :data="nearAccident"
                  label="보고자"
                  type="dept_user"
                  name="reportUserId"
                  v-model="nearAccident.reportUserId">
                </c-field>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-datepicker
                  :editable="editable"
                  :disabled="disabled"
                  type="datetime"
                  label="보고일시"
                  :minuteStep="10"
                  name="reportDt"
                  v-model="nearAccident.reportDt"
                />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-plant 
                  required
                  :disabled="disabled"
                  :editable="editable" 
                  type="edit" 
                  name="plantCd" 
                  v-model="nearAccident.plantCd" />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-radio
                  :editable="editable"
                  :disabled="disabled"
                  codeGroupCd="WEATHER_CD"
                  type="edit"
                  itemText="codeName"
                  itemValue="code"
                  name="weatherCd"
                  label="날씨"
                  v-model="nearAccident.weatherCd"
                ></c-radio>
              </div>
              <!-- <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-dept
                  type="edit"
                  label="발굴부서"
                  name="occurrenceDeptCd"
                  v-model="nearAccident.occurrenceDeptCd">
                </c-dept>
              </div> -->
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <c-risk-assess
                  :editable="editable"
                  :disabled="disabled"
                  name="ramRiskAssessmentPlanId"
                  v-model="nearAccident.ramRiskAssessmentPlanId">
                </c-risk-assess>
                <c-textarea
                  :editable="editable"
                  :disabled="disabled"
                  :rows="2"
                  label="긴급조치사항"
                  name="emergencyMeasures"
                  v-model="nearAccident.emergencyMeasures">
                </c-textarea>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <c-dept
                  type="edit"
                  label="부서"
                  name="occurrenceDeptCd"
                  v-model="nearAccident.occurrenceDeptCd">
                </c-dept>
                <c-textarea
                  :editable="editable"
                  :disabled="disabled"
                  :isCheck="true"
                  :rows="2"
                  label="아차사고사항개요"
                  name="overviewAccidentSituation"
                  v-model="nearAccident.overviewAccidentSituation"
                  @checkboxClick="checkboxClick"
                >
                </c-textarea>
              </div>
              <!-- <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <c-textarea
                  :editable="editable"
                  :disabled="disabled"
                  :rows="2"
                  label="긴급조치사항"
                  name="emergencyMeasures"
                  v-model="nearAccident.emergencyMeasures">
                </c-textarea>
              </div> -->
            </template>  
          </c-card>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-card title="사고발생시 상태" class="cardClassDetailForm">
            <template slot="card-detail">
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-2 col-xl-2">
                <c-checkbox
                  :editable="editable"
                  :disabled="disabled"
                  codeGroupCd="IIM_NEARMISS_DISCERN"
                  :isArray="false"
                  itemText="codeName"
                  itemValue="code"
                  label="인지기능"
                  name="iimNearDiscernCds"
                  v-model="nearAccident.iimNearDiscernCds"
                />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-2 col-xl-2">
                <c-checkbox
                  :editable="editable"
                  :disabled="disabled"
                  codeGroupCd="IIM_NEARMISS_FUNCTION"
                  :isArray="false"
                  itemText="codeName"
                  itemValue="code"
                  label="사고기능"
                  name="iimNearFunctionCds"
                  v-model="nearAccident.iimNearFunctionCds"
                />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-2 col-xl-2">
                <c-checkbox
                  :editable="editable"
                  :disabled="disabled"
                  codeGroupCd="IIM_NEARMISS_FEEL"
                  :isArray="false"
                  itemText="codeName"
                  itemValue="code"
                  label="감정"
                  name="iimNearFeelCds"
                  v-model="nearAccident.iimNearFeelCds"
                />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-2 col-xl-2">
                <c-checkbox
                  :editable="editable"
                  :disabled="disabled"
                  codeGroupCd="IIM_NEARMISS_EQUIP"
                  :isArray="false"
                  itemText="codeName"
                  itemValue="code"
                  label="설비요인"
                  name="iimNearEquipCds"
                  v-model="nearAccident.iimNearEquipCds"
                />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-2 col-xl-2">
                <c-checkbox
                  :editable="editable"
                  :disabled="disabled"
                  codeGroupCd="IIM_NEARMISS_MANAGE"
                  itemText="codeName"
                  itemValue="code"
                  :isArray="false"
                  label="관리요인"
                  name="iimNearManageCds"
                  v-model="nearAccident.iimNearManageCds"
                />
              </div>
            </template>
          </c-card>
        </div>  
        <div class="col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5">
          <c-card title="발생 가능형태">
            <template slot="card-detail">
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <c-select 
                  :editable="editable"
                  :disabled="disabled"
                  :comboItems="occFirstItems"
                  type="edit"
                  itemText="codeName"
                  itemValue="code"
                  name="occurrenceModeLargeCd"
                  label="대분류"
                  v-model="nearAccident.occurrenceModeLargeCd"
                  @datachange="occurDataChange"
                ></c-select>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <c-select
                  :editable="editable"
                  :disabled="disabled"
                  :comboItems="occSecItems"
                  type="edit"
                  itemText="codeName"
                  itemValue="code"
                  name="occurrenceModeSmallCd"
                  label="중분류"
                  v-model="nearAccident.occurrenceModeSmallCd"
                ></c-select>
              </div>
            </template>
          </c-card>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5">
          <c-card title="위험성평가" class="cardClassDetailForm">
            <template slot="card-detail">
              <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <c-text 
                  :editable="editable"
                  :disabled="disabled"
                  type="number"
                  name="frequencyRisk" 
                  label="빈도"
                  v-model="nearAccident.frequencyRisk"
                  @dataChange="dataChange"
                />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <c-text 
                  :editable="editable"
                  :disabled="disabled"
                  type="number"
                  name="strengthRisk" 
                  label="강도"
                  v-model="nearAccident.strengthRisk"
                  @dataChange="dataChange"
                />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <c-text 
                  :editable="editable"
                  :disabled="true"
                  type="number"
                  name="totalRisk" 
                  label="위험도"
                  v-model="nearAccident.totalRisk"
                />
              </div>
            </template>
          </c-card>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-card title="포상금">
            <template slot="card-detail">
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <c-text
                  :editable="editable"
                  :disabled="disabled"
                  label=""
                  suffix="원"
                  type="number"
                  name="bounty"
                  v-model="nearAccident.bounty">
                </c-text>
              </div>
            </template>
          </c-card>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"> 
          <c-card title="사고발생 가능 원인" class="cardClassDetailForm">
            <template slot="card-detail">
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <c-text 
                  :editable="editable"
                  :disabled="disabled"
                  name="directCause" 
                  label="직접원인"
                  v-model="nearAccident.directCause"
                />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <c-text 
                  :editable="editable"
                  :disabled="disabled"
                  name="indirectCause" 
                  label="간접원인"
                  v-model="nearAccident.indirectCause"
                />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <c-text 
                  :editable="editable"
                  :disabled="disabled"
                  name="openning" 
                  label="기인물"
                  v-model="nearAccident.openning"
                />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <c-text 
                  :editable="editable"
                  :disabled="disabled"
                  name="injurious" 
                  label="가해물"
                  v-model="nearAccident.injurious"
                />
              </div>
            </template>
          </c-card>
        </div>  
      </div>
    </q-form>      
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>
<script>
import { uid } from 'quasar'
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'nearRegisterInfo',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        iimNearAccidentId: '',
      }),
    },
    isApprDisabled: {
      type: Boolean,
      default: false
    },
    //nearRelationPicture의 사진을 props로 가져와서 저장
    attachInfo1: {
      type: Object,
      default: function() {
        return {
          isSubmit: '',
          taskClassCd: 'NEAR_FIRST_PICTURE',
          taskKey: '',
        }
      },  
    },
    attachInfo2: {
      type: Object,
      default: function() {
        return {
          isSubmit: '',
          taskClassCd: 'NEAR_SECOND_PICTURE',
          taskKey: '',
        }
      },  
    },
  },
  data() {
    return {
      nearAccident: {
        iimNearAccidentId: '',
        plantCd: '',
        iimNearAccidentNo: '',
        iimNearAccidentName: '',
        iimStatusNearCd: '',
        reportUserId: '',
        reportDt: '',
        iimNearKindCds: '',
        iimGradeCd: null,
        occurrenceDt: '',
        occurrenceLocation: '',
        occurrenceDeptCd: '',
        weatherCd: 'WC000001',
        emergencyMeasures: '',
        overviewAccidentSituation: '',
        occurrenceModeLargeCd: null,
        occurrenceModeSmallCd: null,
        directCause: '',
        indirectCause: '',
        openning: '',
        injurious: '',
        iimNearDiscernCds: null,
        iimNearFunctionCds: null,
        iimNearFeelCds: null,
        iimNearEquipCds: null,
        iimNearManageCds: null,
        processCd: '',
        frequencyRisk: '',
        strengthRisk: '',
        totalRisk: '',
        ramRiskAssessmentPlanId: '',
        bounty: 0,
      },
      occFirstItems: [],
      occSecItems: [],
      weatherItems: [],
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '90%',
        top: '10px',
        param: {},
        closeCallback: null,
      },
      editable: true,
      saveType: 'POST',
      isSave: false,
      isComplete: false,
      isApproval: false,
      saveUrl: '',
      detailUrl: '',
      deleteUrl: '',
      completeUrl: '',
    };
  },
  watch: {
    // 상세조회를 했을 때 대분류에 따른 중분류의 값을 가져오는 부분
    'nearAccident.occurrenceModeLargeCd'() {
      if (!this.nearAccident.occurrenceModeLargeCd) return;
      this.setSecondItems(this.nearAccident.occurrenceModeLargeCd)
    },
  },
  computed: {
    disabled() {
      return (this.editable && Boolean(this.nearAccident.iimStatusNearCd) && this.nearAccident.iimStatusNearCd === 'ISNC000002') || this.nearAccident.approvalStatusCd === 'ASC9999999';
    },
    approvalInfo() {
      return {
        sysApprovalRequestId: this.nearAccident.sysApprovalRequestId, // 결재요청번호
        /**
         * 결재상태코드
         * ASC0000001	결재중
         * ASC0000002	반려
         * ASC9999999	결재완료
         */
        approvalStatusCd: this.nearAccident.approvalStatusCd, 
        apprEditable: !this.disabled && this.popupParam.iimNearAccidentId, // 결재버튼 활성화 기준
        param: this.nearAccident, // 결재 param
        approvalUrl: transactionConfig.sop.iim.accident.near.complete.url, // 결재 url
        isApproval: this.isApproval, // 결재 submit
        approvalTypeCd: 'APTC000016', // 결재유형코드
        approvalParamValue: { // 결재상세화면 파라미터
          iimNearAccidentId: this.popupParam.iimNearAccidentId,
          isApprContent: true
        },
        approvalRequestName: this.$language('아차사고')+' ['+this.nearAccident.iimNearAccidentName+']', // 결재요청명 (문서 title)
        approvalConnId: this.popupParam.iimNearAccidentId, // 결재연결용 업무일련번호 (문서 key)
      }
    },
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.detailUrl = selectConfig.sop.iim.accident.near.get.url;
      this.occurUrl = selectConfig.sop.iim.occurForm.list.url;
      this.saveUrl = transactionConfig.sop.iim.accident.near.insert.url;
      this.deleteUrl = transactionConfig.sop.iim.accident.near.delete.url;
      this.completeUrl = transactionConfig.sop.iim.accident.near.complete.url;
      // code setting
      this.setFirstItems();
      // list setting
      this.getDetail();
    },
    getDetail() {   //상세페이지 항목들에 저장된 값들을 가져오는 부분
      if (this.popupParam.iimNearAccidentId) {
        this.$http.url = this.$format(this.detailUrl, this.popupParam.iimNearAccidentId);
        this.$http.type = 'GET'; 
        this.$http.request((_result) => {
          this.nearAccident = _result.data;
          this.$set(this.attachInfo1, 'taskKey', this.popupParam.iimNearAccidentId)
          this.$set(this.attachInfo2, 'taskKey', this.popupParam.iimNearAccidentId)
          this.$emit('transInfo', _result.data);
        },);
      } else {
        // nothing...
      }
    },
    setFirstItems() {
      this.$http.url = this.occurUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        useFlag: 'Y',   //사용중인 것만 가져옴
        occurLevel: '1',  //발생레벨(깊이)
      }
      this.$http.request((_result) => {
        this.$_.forEach(_result.data, _item => {
          this.occFirstItems.splice(0, 0, {
            code: _item.iimOccurMasterId,   //목록을 가져오는 부분
            codeName: _item.occurName,      //가져온 목록의 이름을 넣는 부분
          })
        })
      },);
    },
    setSecondItems(iimOccurMasterId) {
      this.$http.url = this.occurUrl; 
      this.$http.type = 'GET';
      this.$http.param = {
        iimUpOccurMasterId: iimOccurMasterId,
        useFlag: 'Y',
        occurLevel: '2',
      }
      this.$http.request((_result) => {
        this.occSecItems = []; 
        this.$_.forEach(_result.data, _item => {
          this.occSecItems.splice(0, 0, {
            code: _item.iimOccurMasterId,
            codeName: _item.occurName,
          })
        })
      },);
    },
    dataChange() {  //유효성체크(숫자만 들어오게)
      if(this.nearAccident.frequencyRisk !== null && this.nearAccident.frequencyRisk >= 0 && this.nearAccident.strengthRisk !== null && this.nearAccident.strengthRisk >= 0) {
      this.nearAccident.totalRisk = this.nearAccident.frequencyRisk * this.nearAccident.strengthRisk
      }
    },
    saveNearAccident() {
      if (this.popupParam.iimNearAccidentId) {
        this.saveUrl = transactionConfig.sop.iim.accident.near.update.url;
        this.saveType = 'PUT';
      } else {
        this.saveUrl = transactionConfig.sop.iim.accident.near.insert.url;
        this.saveType = 'POST';
      }
      this.$refs['editForm'].validate().then(_result => {
        if (_result) { 
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '저장하시겠습니까?',
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.nearAccident.regUserId = this.$store.getters.user.userId
              this.nearAccident.chgUserId = this.$store.getters.user.userId   
              this.isSave = !this.isSave;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      if (!this.popupParam.iimNearAccidentId) {
        this.$emit('changeStatus')  //개선관리 탭을 추가하는 부분(Detail -> RegisterTab -> RegisterInfo 순으로 되어있음)
        this.popupParam.iimNearAccidentId = result.data;
        // 관련사진에 사진을 저장하는 부분(없으면 상세조회 때 사진을 가져올 수 없음)
        this.$set(this.attachInfo1, 'taskKey', result.data)
        this.$set(this.attachInfo2, 'taskKey', result.data)
        this.$set(this.attachInfo1, 'isSubmit', uid())
        this.$set(this.attachInfo2, 'isSubmit', uid())
      }
      this.getDetail();
    },
    /*completeNearAccident() {
      this.$refs['editForm'].validate().then(_result => {
        if (_result) { 
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '완료하시겠습니까?',
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.nearAccident.regUserId = this.$store.getters.user.userId
              this.nearAccident.chgUserId = this.$store.getters.user.userId
              this.isComplete = !this.isComplete
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    completeCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      // 완료버튼을 눌러서 완료한 뒤 ISNC000002(등록완료)를 전달하는 부분
      this.$emit('changeComplete')
      this.getDetail();
    },*/
    /* eslint-disable no-unused-vars */
    saveDataAppr() {
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '결재요청 하기 전 입력된 값을 저장합니다. 진행하시겠습니까?',
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.nearAccident.regUserId = this.$store.getters.user.userId
          this.nearAccident.chgUserId = this.$store.getters.user.userId
          let promises = [
            {
              func: this.approvalValue
            },
          ];
          this.$comm.orderedPromise(promises);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    // [S] 결재관련 
    approvalCallback() { // 결재관련 결재요청버튼 callback
      this.$refs['appr-btn'].apprRequestPop();
    },
    approvalValue() {
      return new Promise(resolve => {
        this.isApproval = !this.isApproval
        resolve(true);
      });
    },
    remove() {
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '삭제하시겠습니까?',
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.popupParam.iimNearAccidentId);
          this.$http.type = 'DELETE';
          this.$http.request((_result) => {
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.$emit('closePopup')
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    occurDataChange() {
      if (!this.nearAccident.occurrenceModeLargeCd){
        this.nearAccident.occurrenceModeSmallCd = null;
        this.occSecItems = [];
      } else {
        this.nearAccident.occurrenceModeSmallCd = null;
        this.setSecondItems(this.nearAccident.occurrenceModeLargeCd)
      }
    },
    checkboxClick(checkValue) {
      if (checkValue === 'O') {
        this.nearAccident.overviewAccidentSituation = 
          '- ' + this.$language('누가')+' : \n' +
          '- ' + this.$language('누구에게')+' : \n' + 
          '- ' + this.$language('언제')+' : \n' + 
          '- ' + this.$language('어디서')+' : \n' + 
          '- ' + this.$language('무엇을')+' : \n' + 
          '- ' + this.$language('어떻게')+' : \n' + 
          '- ' + this.$language('왜')+' : ';
      } else {
        this.nearAccident.overviewAccidentSituation = '';
      }
    },
  }
};       
  </script>